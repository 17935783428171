ion-content#loginPage {
    ion-grid {
        .form {
            width: 100%;
            
            ion-button {
                margin-top: 20px;
            }

            .error-note {
                margin: 25px 0 0;
                text-align: center;
                width: 100%;
                display: inline-block;
            }
        }

        .loginBtn {
            font-size: 16px;
            --border-radius: 100px;
            --padding-end: 50px;
            --padding-start: 50px;
            height: 60px;
        }
    }
}

.desktopLogo {
    display: none;
}

@media screen and (min-width: 991px) {
    ion-content#loginPage {
        .page-content {
            width: 650px;
            margin: 0 auto 0 0;
            height: auto;
            min-height: 100vh;
            background: var(--ion-item-background);
            padding: 40px 80px 40px 80px;
            box-shadow: 0 0 35px -5px rgba(0, 0, 0, 0.15);

            display: flex;
            flex-flow: column;
            justify-content: center;
        }

        ion-grid {
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 100vh;
            padding: 0;
            background: url('../assets/images/login_bg.jpg') 100% 100% no-repeat;
            background-size: cover;

            .form {
                width: 100%;
            }
        }

        h1.pageTitle {
            display: none;
        }
    }

    .desktopLogo {
        display: block;
        text-align: center;
        margin: 20px auto 50px;
        width: 175px;
    }
}

@media screen and (min-width: 991px) and (prefers-color-scheme: dark) {
    ion-content#loginPage {
        ion-grid {
            background: url('../assets/images/login_bg_dark.jpg') 100% 100% no-repeat;
            background-size: cover;
        }
    }
}
