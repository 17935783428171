.bottomMargin {
    margin-bottom: 20px;
}

.highlightedSelect {
    --background: var(--ion-color-primary);
    color: #fff;
}

.datamodListModal,
.datamodList {
    ion-item {
        ion-label {
            color: var(--ion-color-tertiary) !important;
        }

        ion-badge {
            font-size: 11px;
        }

        ion-select::part(icon) {
            display: none !important;
        }
    }

    ion-card-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 18px;

        .chevronIcon {
            &.isClosed {
                transform: rotateX(0);
                transition: transform .15s ease-in;
            }

            &.isOpen {
                transform: rotateX(180deg);
                transition: transform .15s ease-in;
            }
        }
    }
    
    ion-card-content {
        padding-left: 0;
        max-height: 0;
        overflow: hidden;

        &.isOpen {
            max-height: max-content;
        }

        &.isClosed {
            padding: 0;
        }

        .bottomPaddingButton {
            display: flex;
            justify-content: flex-end;
            padding: 20px 0 0 0;
        }
    }

    .item-interactive-disabled:not(.item-multiple-inputs) .sc-ion-label-ios-h {
        opacity: 1;
        // color: var(--ion-color-grey) !important;   
    }
    
    .native-input[disabled].sc-ion-input-ios {
        opacity: 1;
        // color: var(--ion-color-grey) !important;   
    }

    .item-interactive {
        &:before {
            position: relative;
            content: '';
            background: var(--ion-color-primary);
            width: 2px;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 5;
        }

        &.hasChanged {
            ion-label {
                color: var(--ion-color-warning) !important;
            }

            &:before {
                background: var(--ion-color-warning);
            }
        }

        &.item-interactive-disabled {
            &:before {
                background: transparent;
            }
        }
    }

    .backspaceButton {
        position: absolute;
        right: 0;
        bottom: 4px;
        z-index: 50;
    }

    ion-icon.backspaceButton {
        font-size: 18px;
        color: var(--ion-color-grey) !important;

    }
}

ion-select.fullLength {
    min-width: 100%;
    --padding-start: 0;
}

.paddingForButton {
    padding: 25px;
}
