.newCustomerNumber {
    color: var(--ion-color-secondary);
    position: absolute;
    right: 20px;
    top: 80px;
    text-align: right;
    display: flex;
    align-items: center;

    .newCustomerNumber-text {
        margin-right: 10px;
        font-size: 14px;
        line-height: 17px;
    }

    ion-button {
        --padding-start: 10px;
        --padding-end: 10px;
    }
}

.cardButton {
    text-align: center;
    padding: 32px 0 22px;
    --color: var(--ion-color-secondary);
    box-shadow: 0 3px 18px #0024881F;

    &.alert {
        padding: 0;

        #invoiceAlert {
            border-left: 3px solid var(--ion-color-danger);
            padding: 15px;
            color: var(--ion-color-danger);
            display: flex;
            align-items: center;

            ion-icon {
                font-size: 24px;
                margin: 0 10px 0 0;
                color: var(--ion-color-danger);
            }
        
            h2 {
                margin: 0;
                font-size: 16px;
                color: var(--ion-color-danger);
            }
        
            &.success {
                border-left: 3px solid var(--ion-color-success);
                color: var(--ion-color-success);

                ion-icon {
                    color: var(--ion-color-success);
                }

                h2 {
                    color: var(--ion-color-success);
                }
            }
        }
    }

    ion-icon {
        font-size: 60px;
        margin-bottom: 25px;
        color: var(--ion-color-primary);
    }

    h2 {
        font-size: 14px;
        margin: 0;
        font-weight: bold;
    }

    ion-badge {
        position: absolute;
        right: 14px;
        top: 13px;
    }
}

#desktopWelcome {
    display: none;
}

@media screen and (min-width: 992px) {
    #desktopWelcome {
        position: relative;
        display: flex !important;
        height: 130px;
        width: 100%;
        flex-flow: column;
        background: #0C4E6E;
        /*background: linear-gradient(165deg, rgba(0,173,238,1) 0%, rgba(43,101,247,1) 100%);*/
        border-radius: 14px;
        margin-bottom: 20px;
        align-items: flex-start;
        justify-content: center;
        padding: 0 30px;

        h2 {
            color: #fff;
            font-weight: 600;
            font-size: 34px;
            margin: 0 0 15px;
            line-height: 34px;
        }

        p {
            color: #fff;
            font-size: 17px;
            font-weight: 100;
            margin: 0;
            opacity: .8;

            a {
                color: #fff;
                text-decoration: underline;
            }
        }

        &::after {
            content: '';
            background: url('../../assets/images/male.png') 100% 100% no-repeat;
            background-size: contain;
            position: absolute;
            right: 60px;
            bottom: 0;
            width: 220px;
            height: 220px;
        }
    }

    .cardButton {
        padding: 62px 0 52px;
        transition: background .15s ease;

        ion-icon {
            font-size: 70px;
            transition: color .15s ease;
        }

        h2 {
            font-size: 20px;
            transition: color .15s ease;
        }

        &:hover {
            background: var(--ion-color-secondary);
            transition: background .15s ease;

            h2 {
                color: #fff;
                transition: color .15s ease;
            }

            ion-icon {
                color: #fff;
                transition: color .15s ease;
            }
        }
    }
}
